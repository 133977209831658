@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Catamaran&display=swap");
@import url("./font.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat-Light", sans-serif;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.brand-font {
  font-family: "MilanesaSerif-ExtraBold";
  line-height: 1em;
  display: inline-block;
  transform: scale(1, 2);
  -webkit-transform: scale(1, 1.5);
  -moz-transform: scale(1, 1.5);
  -o-transform: scale(1, 1.5);
  transform-origin: 0% 70%;
}

.brand-font-p {
  font-family: "MilanesaSerif-ExtraBold";
  line-height: 1em;
  display: inline-block;
  transform: scale(1, 2);
  -webkit-transform: scale(1, 1.5);
  -moz-transform: scale(1, 1.5);
  -o-transform: scale(1, 1.5);
  transform-origin: 0% 70%;
}

.swiper {
  width: 100%;
  height: 500px;
}

.swiper-slide {
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide picture {
  display: block;
  height: 100%;
  min-width: 340px;
  object-fit: cover;
}
.swiper-slide img {
  display: block;
  height: 100%;
  min-width: 340px;
  object-fit: cover;
}

.swiper-color .swiper-button-next {
  color: white;
}
.swiper-color .swiper-button-prev {
  color: white;
}

.swiper-replace .swiper-button-next {
  background-image: url("../public/assets/icons/chevron-down-circle.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  rotate: 270deg;
}

.italic-light {
  font-family: "Montserrat-LightItalic", sans-serif;
}

.bold {
  font-family: "Montserrat-Bold", sans-serif;
}
.semi-bold {
  font-family: "Montserrat-SemiBold", sans-serif;
}
.light {
  font-family: "Montserrat-Light", sans-serif;
}
hr {
  color: #000000;
}

.bun-background {
  background-color: #fff;
}

.text-darker {
  color: #212123;
}

@media screen and (max-width: 508px) {
  .footer-box {
    width: 100%;
    text-align: center;
  }

  .footer-button {
    justify-content: center;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 600px;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.copy-button {
  border: none;
  background: transparent;
  cursor: pointer;
  color: whitesmoke;
  padding: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url(''); */
  background-image: url("/public/assets/images/PappaRotiKenyaBuns.webp");
}
