.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}

.hamburgerMenu {
  transform: rotate(270deg);
  width: 60px;
  height: 60px;
}

.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}


.nav {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav img {
  height: 2.5rem;
  width: auto;
  border-radius: 0;
}

.nav__logo, 
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 3rem;

}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: .3s;

}

.nav__icon,
.nav__icon__comp,
.nav__close,
.nav__toggle {
  display: none;
}



.nav__link:hover {
  color: var(--title-color-dark);
}


.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

  /* <!-- =============== BREAKPOINTS  =================== --> */


  @media screen and (max-width: 768px) {
    .header {
      top: 0;
      bottom: initial;
    }

    .container {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    .nav {
      height: var(--header-height);
    }

    /* .nav__toggle, */
    .nav__logo {
      display: none;
    }

    .nav__menu {
      position: fixed;
      top: -100%;
      left: 0;
      width: 100%;
      background-color: #513531;
      padding: 2rem 1.5rem 4rem;
      box-shadow: 0 -1px 4px #513531;
      border-radius: 0 0 1.5rem 1.5rem;
      transition: .3s;

    }

    /* show menu */
    .show-menu  {
      top: 0;  
      
    }

    .menu__icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    .nav__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }

    .nav__icon {
      font-size: 1.5rem;
    }
    .nav__icon__comp {
      height: 1.5rem;
      width: 1.5rem;
    }

    .nav__close {
      position: absolute;
      left: 1.3rem;
      top: 1rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: #513531;

    }

    .nav__close:hover {
      color: #513531;
    }

    .nav__toggle {
      font-size: 1.1rem;
      cursor: pointer;
    }
    .nav__icon,
    .nav__icon__comp,
    .nav__close,
    .nav__toggle {
      display: block;
    }
  }

    /* small */
    @media screen and (max-width: 350px) {
      .nav__menu {
        padding: 2rem 0.25rem 4rem;
      }

      .nav__list {
        column-gap: 0.5rem;
      }
      .container {
        margin-left: 1rem;
        margin-right: 1rem;
    }

  }