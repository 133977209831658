@font-face {
  font-family: 'MilanesaSerif-ExtraBold';
  src: local("MilanesaSerif-ExtraBold"),
   url('./fonts/MilanesaSerif-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local("Montserrat-Bold"),
   url('./fonts/Montserrat-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Montserrat-Light';
  src: local("Montserrat-Light"),
   url('./fonts/Montserrat-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local("Montserrat-Regular"),
   url('./fonts/Montserrat-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local("Montserrat-SemiBold"),
   url('./fonts/Montserrat-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-LightItalic';
  src: local("Montserrat-LightItalic"),
   url('./fonts/Montserrat-LightItalic.otf') format('opentype');
}